import React, { useState, useEffect, useRef } from "react";
import { Draggable, Droppable, DragDropContext } from "react-beautiful-dnd";
import { BsCheck, BsTrash, BsArrowRepeat } from "react-icons/bs";
// Import Bootstrap and its default variables
import "./App.scss";


const TodoList = () => {
  const [tasks, setTasks] = useState([]);
  const [taskInput, setTaskInput] = useState("");
  const [error, setError] = useState("");
  const [editableItemId, setEditableItemId] = useState(null);
  const inputRef = useRef(null);

  useEffect(() => {
    const storedTasks = JSON.parse(localStorage.getItem("tasks")) || [];
    setTasks(storedTasks);
  }, []);

  const addTask = () => {
    if (taskInput.trim() !== "") {
      const newTask = { id: Date.now(), text: taskInput, completed: false };
      setTasks([...tasks, newTask]);
      setTaskInput("");
      setError("");

      localStorage.setItem("tasks", JSON.stringify([...tasks, newTask]));
    } else {
      setError("Please enter a task before adding.");
    }
  };

  const deleteTask = (taskId, e) => {
    e.stopPropagation();
    const updatedTasks = tasks.filter((task) => task.id !== taskId);
    setTasks(updatedTasks);
    localStorage.setItem("tasks", JSON.stringify(updatedTasks));
  };

  const completeTask = (taskId, e) => {
    e.stopPropagation();
    const updatedTasks = tasks.map((task) =>
      task.id === taskId ? { ...task, completed: !task.completed } : task
    );
    setTasks(updatedTasks);
    localStorage.setItem("tasks", JSON.stringify(updatedTasks));
  };

  const updateTaskText = (taskId, newText) => {
    const updatedTasks = tasks.map((task) =>
      task.id === taskId ? { ...task, text: newText } : task
    );
    setTasks(updatedTasks);
    localStorage.setItem("tasks", JSON.stringify(updatedTasks));
    setEditableItemId(null);
  };

  const handleTextClick = (taskId) => {
    setEditableItemId(taskId);
  };

  const handleItemClick = (taskId, e) => {
    completeTask(taskId, e);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      if (editableItemId !== null) {
        updateTaskText(editableItemId, inputRef.current.value);
      } else {
        addTask();
      }
    }
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const reorderedTasks = [...tasks];
    const [removed] = reorderedTasks.splice(result.source.index, 1);
    reorderedTasks.splice(result.destination.index, 0, removed);

    setTasks(reorderedTasks);
    localStorage.setItem("tasks", JSON.stringify(reorderedTasks));
  };

  return (
    <div className="container mt-5">
      <h1 className="text-center">Todo List</h1>
      <div className="input-group input-group-lg mb-3">
        <input
          type="text"
          className="form-control"
          placeholder="Enter task..."
          value={taskInput}
          onChange={(e) => setTaskInput(e.target.value)}
          onKeyDown={handleKeyDown}
          ref={inputRef}
        />
        <button className="btn btn-primary" type="button" onClick={addTask}>
          Add Task
        </button>
      </div>
      {error && <div className="alert alert-danger">{error}</div>}
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="tasks">
          {(provided) => (
            <ul
              className="list-group"
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {tasks.map((task, index) => (
                <Draggable
                  key={task.id}
                  draggableId={task.id.toString()}
                  index={index}
                >
                  {(provided) => (
                    <li
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      className={`list-group-item d-flex justify-content-between align-items-center ${
                        task.completed ? "text-body-tertiary" : ""
                      }`}
                      onClick={(e) => handleItemClick(task.id, e)}
                    >
                      <div className="col">
                        {editableItemId === task.id ? (
                          <input
                            type="text"
                            className="form-control"
                            defaultValue={task.text}
                            onKeyDown={(e) => handleKeyDown(e)}
                            ref={inputRef}
                          />
                        ) : (
                          <span onClick={() => handleTextClick(task.id)}>
                            {task.text}
                          </span>
                        )}
                      </div>
                      <div className="col-auto">
                        <button
                          className={`btn mx-2 ${task.completed ? "btn-secondary" : "btn-success"}`}
                          onClick={(e) => completeTask(task.id, e)}
                        >
                          {task.completed ? <BsArrowRepeat /> : <BsCheck />}
                          
                        </button>
                        <button
                          className="btn btn-danger"
                          onClick={(e) => deleteTask(task.id, e)}
                        >
                          <BsTrash />
                        </button>
                      </div>
                    </li>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </ul>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
};

function App() {
  return (
    <div className="App">
      <TodoList />
    </div>
  );
}

export default App;
